import React from "react";
import HelpPage from "../../components/helpPage";
import { Tabs, Tab, TabPanel, TabList } from "react-web-tabs";
import "react-web-tabs/dist/react-web-tabs.css";
import { graphql } from "gatsby";
import Img from "gatsby-image";

export const query = graphql`
  query {
    zdjecie1: file(relativePath: { eq: "Import-danych-w-systemie-CRM.png" }) {
      childImageSharp {
        fluid(maxWidth: 1100, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie2: file(
      relativePath: { eq: "Import-danych-z-pliku-w-systemie-CRM.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1100, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie3: file(relativePath: { eq: "Import-danych-zalaczenie-pliku.png" }) {
      childImageSharp {
        fluid(maxWidth: 1100, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie4: file(
      relativePath: {
        eq: "Import-danych-do-systemu-CRM-przypisanie-kolumn.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1100, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie5: file(
      relativePath: { eq: "Zaimportowane-dane-w-systemie-CRM.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1100, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie6: file(
      relativePath: { eq: "Import-osob-kontaktowych-QuickCRM.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1100, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie7: file(
      relativePath: {
        eq: "Import-osob-kontaktowych-i-wiazanie-ich-z-firma-QuickCRM.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1100, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie8: file(
      relativePath: { eq: "Import-danych-z-pliku-w-systemie-CRM.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1100, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie9: file(relativePath: { eq: "Import-danych-w-systemie-CRM.png" }) {
      childImageSharp {
        fluid(maxWidth: 1100, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const ImportDanych = ({ data }) => {
  return (
    <HelpPage
      name="Import danych"
      metaTitle="Pomoc - import danych w prostym systemie CRM - QuickCRM"
      metaDescription="Import danych w systemie CRM - pomoc • Jak importować dane osób kontaktowych i firm do systemu? Co zrobić, by usprawnić sprzedaż? • Wypróbuj QuickCRM i sprzedawaj więcej, pierwsze 30 dni bezpłatnie!"
    >
      <div>
        <p>
          QuickCRM umożliwia import danych do systemu z pliku CSV i XLSX. Import
          dotyczy osób kontaktowych i firm. Dzięki tej funkcji możesz bardzo
          szybko stworzyć bazę kontrahentów w systemie.
        </p>
      </div>
      <Tabs defaultTab="zakladka1">
        <TabList>
          <Tab tabFor="zakladka1">[1] Import kontrahentów</Tab>
          <Tab tabFor="zakladka2">[2] Import osób</Tab>
        </TabList>
        <TabPanel tabId="zakladka1">
          <p>
            Import firm i osób kontaktowych odbywa się w dwóch etapach. W
            pierwszym kroku dodajemy firmy, a następie dołączamy do nich osoby
            kontaktowe. Jeżeli nie chcesz importować firm, przejdź do opisu —
            import osób.
          </p>
          <p>
            <strong>Przygotowanie pliku</strong>
            <br />
            </p>
            <p>
            ID Importu — najważniejszy atrybut w&nbsp;pliku:
            <br />
            a) jeżeli w systemie nie ma dodanych kontrahentów — w pliku w
            kolumnie ID Importu zacznij numeracje od 1,
            <br />
            b) jeżeli w systemie ręcznie zostały utworzone kartoteki
            kontrahentów — w&nbsp;pliku w kolumnie ID Importu zacznij numeracje od 1,
            <br />
            b) jeżeli to kolejny import firm z pliku — sprawdź w systemie
            pierwszy wolny numer w kolumnie Import ID i wpisz go do pliku jako
            pierwszy.
            <br />
            <br />
            <strong>Przykładowy plik importu:</strong>{" "}
            <a href="/Import-Kontrahentow-QuickCRM.xlsx">Import firm</a>
            <br />
            <br />
            </p>
            <p>
            <strong>Zasady:</strong>
            <br />
            </p>
            <p>
            a) nie używaj znaków specjalnych “+” lub “()” w numerze telefonu,
            <br />
            b) usuń hiperłącza w adresie strony/maila,
            <br />
            c) usuń nadmiarowe spacje w obrębie całego dokumentu,
            <br />
            d) nie importuj niepotrzebnych kolumn (bez danych),
            <br />
            e) zaznacz wszystkie komórki i sformatuj jako tekst.
          </p>
          <p>
            <strong>Import firm do systemu</strong>
          </p>
          <p>
            [1] Wybierz z menu pozycję “Ustawienia” i przejdź dalej do “Import
            danych”.
          </p>
          <Img
            style={{
              width: 1100,
              margin: "0 auto",
              maxWidth: "100%"
            }}
            alt="Import-danych-w-systemie-CRM"
            fluid={data.zdjecie1.childImageSharp.fluid}
          />
          <p>[2] W tym przypadku wybieramy opcję “Import z pliku XLSX”.</p>
          <Img
            style={{
              width: 1100,
              margin: "0 auto",
              maxWidth: "100%"
            }}
            alt="Import-danych-z-pliku-w-systemie-CRM"
            fluid={data.zdjecie2.childImageSharp.fluid}
          />
          <p>[3] Dodaj odpowiednio przygotowany plik.</p>
          <p>
            [4] Wybierz typ importu. Jeżeli chcesz zaimportować firmy i osoby
            kontaktowe, zacznij od dodawania firm.
          </p>
          <p>[5] Wybierz “Dalej”, jeśli uzupełniłeś powyższe kroki.</p>
          <Img
            style={{
              width: 1100,
              margin: "0 auto",
              maxWidth: "100%"
            }}
            alt="Import-danych-załączenie-pliku"
            fluid={data.zdjecie3.childImageSharp.fluid}
          />
          <p>
            [6] Pamiętaj, aby zaznaczyć opcję “pomiń pierwszą linię”, jeśli w
            pliku zostawiłeś nagłówki (np. “Kontrahent”, “Miasto”).
          </p>
          <p>[7] Wpisz nazwę szablonu np. “Import kontrahentów”.</p>
          <p>
            [8] Do każdej kolumny z pliku przypisz taką samą nazwę w systemie,
            aby prawidłowo zaimportować dane.
          </p>
          <Img
            style={{
              width: 1100,
              margin: "0 auto",
              maxWidth: "100%"
            }}
            alt="Import-danych-do-systemu-CRM-przypisanie-kolumn"
            fluid={data.zdjecie4.childImageSharp.fluid}
          />
          <Img
            style={{
              width: 1100,
              margin: "0 auto",
              maxWidth: "100%"
            }}
            alt="Zaimportowane-dane-w-systemie-CRM"
            fluid={data.zdjecie5.childImageSharp.fluid}
          />
          <p>Dane zostały zaimportowane do systemu, przejdź do listy firm.</p>
        </TabPanel>
        <TabPanel tabId="zakladka2">
          <p>
            <strong>
              {" "}
              Importowanie osób kontaktowych występuje w dwóch przypadkach:
            </strong>
             <br />
            </p>
            <p>
            a) import osób bez wiązania ich z firmą,
            <br />
            b) import osób powiązanych z firmą — należy przypisać osobę do firmy
            wybierając właściwy numer w polu ID Firmy.
            <br />
            </p>
            <p>
            <strong>Przygotowanie pliku</strong>
            <br />
            </p>
            <p>
            ID Importu — najważniejszy atrybut w&nbsp;pliku:
            <br />
            a) jeżeli w systemie nie ma dodanych kontrahentów — w pliku w
            kolumnie ID Importu zacznij numeracje od 1,
            <br />
            b) jeżeli w systemie ręcznie zostały utworzone kartoteki
            kontrahentów — w&nbsp;pliku w kolumnie ID Importu zacznij numeracje od 1,
            <br />
            b) jeżeli to kolejny import firm z pliku — sprawdź w systemie
            pierwszy wolny numer w kolumnie Import ID i wpisz go do pliku jako
            pierwszy.
            <br />
            </p>
            <p>
            <strong>Zasady:</strong>
            <br />
            </p>
            <p>
            a) nie używaj znaków specjalnych “+” lub “()” w numerze telefonu,
            <br />
            b) usuń hiperłącza w adresie strony/maila,
            <br />
            c) usuń nadmiarowe spacje w obrębie całego dokumentu,
            <br />
            d) nie importuj niepotrzebnych kolumn (bez danych),
            <br />
            e) zaznacz wszystkie komórki i sformatuj jako tekst.
          </p>
          <br />
          <strong>Przykładowy plik importu:</strong>{" "}
          <a href="/Import-Osob-Kontaktowych-QuickCRM.xlsx">
            Import osób kontaktowych
          </a>
          <br />
          <br />
          <p>
            <strong>Import osób kontaktowych</strong>
          </p>
          <p>
            [1] Wybierz z menu pozycję “Ustawienia” i przejdź dalej do “Import
            danych”.
          </p>
          <Img
            style={{
              width: 1100,
              margin: "0 auto",
              maxWidth: "100%"
            }}
            alt="Import-osób-kontaktowych-QuickCRM"
            fluid={data.zdjecie6.childImageSharp.fluid}
          />
          <p>[2] W tym przypadku wybieramy opcję “Import z pliku XLSX”.</p>
          <Img
            style={{
              width: 1100,
              margin: "0 auto",
              maxWidth: "100%"
            }}
            alt="Import-osób-kontaktowych-i-wiązanie-ich-z-firmą-QuickCRM"
            fluid={data.zdjecie7.childImageSharp.fluid}
          />
          <p>[3] Dodaj odpowiednio przygotowany plik.</p>
          <p>[4] Wybierz typ importu — osoby kontaktowe.</p>
          <p>[5] Wybierz “Dalej”, jeśli uzupełniłeś powyższe kroki.</p>
          <Img
            style={{
              width: 1100,
              margin: "0 auto",
              maxWidth: "100%"
            }}
            alt="Import-danych-z-pliku-w-systemie-CRM"
            fluid={data.zdjecie8.childImageSharp.fluid}
          />
          <p>
            [6] Pamiętaj o zaznaczeniu “pomiń pierwszą linię” jeśli w pliku
            znajdują się nagłówki.
          </p>
          <p>[7] Wpisz nazwę importu.</p>
          <p>
            <strong>
              W tym przypadku importujesz osoby i&nbsp;przypisujesz je do firm za
              pomocą kolumny “ID Firmy”. Upewnij się, że użyłeś odpowiedniej
              numeracji. Na podanym przykładzie dwóch pierwszych pracowników
              zostanie przypisanych do firmy o numerze “1” (ID Firmy), ponieważ
              w polu ID Firmy wpisano wartość “1”.
              <br />
              Jeżeli nie chcesz wiązać osób z&nbsp;firmami usuń z przykładowego pliku
              kolumnę “ID Firmy”.
            </strong>
          </p>
          <p>[8] Wybierz “Dalej”, jeśli uzupełniłeś powyższe kroki.</p>
          <Img
            style={{
              width: 1100,
              margin: "0 auto",
              maxWidth: "100%"
            }}
            alt="Import-danych-w-systemie-CRM"
            fluid={data.zdjecie9.childImageSharp.fluid}
          />
          <p>
            Osoby zostały zaimportowane. Możesz to sprawdzić przechodząc do
            listy osób aktywnych (menu>parametry kontrahentów>osoby kontaktowe
            (aktywne)).
          </p>
        </TabPanel>
      </Tabs>
    </HelpPage>
  );
};

export default ImportDanych;
